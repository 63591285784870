import './footer.scss';

function Footer() {
    return (
        <footer>
            <div className='container'>
                <p>Copyright 2024<span>v0.0.7</span></p>
            </div>
        </footer>
    );
}

export default Footer;