import axios, { AxiosInstance } from "axios"
import { toast } from "react-toastify";
import { appMessageLocalization } from "../../services/app-messages-service";

export function getApi(auth: boolean = false): AxiosInstance {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    let args: any = {
        withCredentials: false,
        baseURL: API_BASE_URL
    }

    if (auth) {
        const userString = localStorage.getItem('user');
        if (userString !== null && userString !== undefined && userString !== "undefined") {
            const user = JSON.parse(userString!);

            if (user === undefined) {
                localStorage.removeItem('user');
                window.location.href = '/login';
            }

            let token = user!.token;

            args = {
                ...args,
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        }
        else {
            localStorage.removeItem('user');
            window.location.href = '/login';
        }

    }

    const api = axios.create(args);

    api.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        if (error?.response?.status === 401) {
            localStorage.removeItem('user');
            window.location.href = '/login';
        }
        else if (error?.response?.status == 403) {
            localStorage.removeItem('user');
            window.location.href = '/login';
        }
        else if (400) {
            const m = error?.response?.data?.error;

            toast.error(appMessageLocalization(m), {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
        else {
            return Promise.reject(error);
        }
    });

    return api;
}