import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

function Layout() {
    useEffect(() => {
        document.body.classList.add('login');
      }, []);
      
    return (<Outlet />);
}

export default Layout;