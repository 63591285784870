import { defineCancelApiObject } from "./configs/axios-utils"
import { httpApiService } from "./services/http-api-service";

export interface IProduct {
  id: string;
  sku: string;
  name: string;
  brandName: string;
  image: string;
  price: number;
  priceWithDiscount: number;
  preOrdered: number;
  shipped: number;
  remaining: number;
  inStock: number;
}

export interface IProducts {
  count: number,
  items: IProduct[]
}

export const ProductAPI = {
  defaultPageSize: 12,

  getAll: async function (page = 0, term = "", categoryFilter = "" , stockFilter = "", sort = "", sortIsDesc = true, cancel = false) : Promise<IProducts> {

    const filterStock = stockFilter == "in-stock" ? 1 : (stockFilter == "out-of-stock" ? 2 : 0);
    const orderBy = sort;
    const orderDirection = sortIsDesc ? 1 : 0;

    const response = await httpApiService.get<IProducts>(`front-office/products?`
      + `filter.searchTerm=${term}&`
      + `filter.categoryId=${categoryFilter}&`
      + `filter.stock=${filterStock}&`
      + `filter.page=${page}&`
      + `filter.pageSize=${this.defaultPageSize}&`
      + `order.orderBy=${orderBy}&`
      + `order.OrderDirection=${orderDirection}`, true);
    
    return response === undefined ? {count : 0, items: []} : response;
  }  
}

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(ProductAPI)