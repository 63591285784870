import React, { useState, ChangeEvent, FormEvent } from 'react';
import './change-password.scss';
import useLocalStorageState from 'use-local-storage-state';
import { AccountApi, IChangePasswordDto } from '../../apis/account-api'; 
import { User } from '../../types/user';
import { toast } from 'react-toastify';

function Changepassword() {
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [user] = useLocalStorageState<User>('user', {})

    const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    };

    const handleNewPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
        setNewPassword(event.target.value);
    };

    const handleConfirmPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(event.target.value);
    };

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (newPassword !== confirmPassword) {
            setErrorMessage("New password and confirm password don't match");
            return;
        }
        if (!isValidPassword(newPassword)) {
            setErrorMessage("Password must contain at least one lowercase letter, one uppercase letter, one number, and one special character and must be at least 8 characters");
            return;
        }
        
        try {
            let dto: IChangePasswordDto = {
                userId: user!.userId,
                currentPassword: password,
                newPassword: newPassword
            };
            let r = await AccountApi.changePassword(dto);
            if (r){                
                toast.success('Password change successfull!', {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
    
                setPassword('');
                setNewPassword('');
                setConfirmPassword('');
                
                window.location.href = '/';
            }
        } catch (error) {
            console.error("Error while changing password:", error);
            setErrorMessage("An error occurred while changing password. Please try again later.");
        }
    };

    const isValidPassword = (password: string) => {
        const lowercaseRegex = /[a-z]/;
        const uppercaseRegex = /[A-Z]/;
        const numberRegex = /[0-9]/;
        const specialCharacterRegex = /[!@#$%^&*()-_=+[{}\\]|;:',<.>?]/;
    
        const isLowercase = lowercaseRegex.test(password);
        const isUppercase = uppercaseRegex.test(password);
        const isNumber = numberRegex.test(password);
        const isSpecialCharacter = specialCharacterRegex.test(password);
        const isLengthValid = password.length >= 8;
    
        if (!isLengthValid) {
            setErrorMessage('Password must be at least 8 characters long');
            return false;
        }
    
        return isLowercase && isUppercase && isNumber && isSpecialCharacter;
    };

    return (
        <div className='page'>
            <div className="password-container">
                <div className="change-password">
                    <h1>Change your password</h1>
                    <form onSubmit={handleSubmit}>
                        <label>Current password</label>
                        <input type="password" value={password} onChange={handlePasswordChange} />
                        <label>New password</label>
                        <input type="password" value={newPassword} onChange={handleNewPasswordChange} />
                        <label>New password again</label>
                        <input type="password" value={confirmPassword} onChange={handleConfirmPasswordChange} />
                        {errorMessage && <p className="error-message">{errorMessage}</p>}
                        <button type="submit">Save</button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Changepassword;
