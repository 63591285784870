import { getApi } from "../configs/axios-configs";

export const httpApiService = {
    get: async function <R = any>(url: string, auth: boolean): Promise<R | undefined> {
        try {
            const response = await getApi(auth).get<R>(url);
            if (response === undefined) {
                // window.location.href = '/no-service';
            }
            return response?.data
        }
        catch (e) {
            // window.location.href = '/no-service';
            return undefined;
        }
    },

    downloadFile: async function (url: string, auth: boolean) {
        const axios = getApi(auth);

        axios.get<any>(url)
            .then(response => {
                const contentDisposition = response.headers['content-disposition'];

                let fileName = "temp";
                if (contentDisposition) { 
                    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    var matches = filenameRegex.exec(contentDisposition);
                    if (matches != null && matches[1]) { 
                        fileName = matches[1].replace(/['"]/g, '');
                    }
                }

                var binaryData = [];
                binaryData.push(response.data);
                const href = window.URL.createObjectURL(new Blob(binaryData))

                const anchorElement = document.createElement('a');
                anchorElement.href = href;            
                anchorElement.download = fileName;

                document.body.appendChild(anchorElement);
                anchorElement.click();

                document.body.removeChild(anchorElement);
                window.URL.revokeObjectURL(href);
            })
            .catch(error => {
                console.log('error: ', error);
            });
    }
}