import { getApi } from "./configs/axios-configs"
import { defineCancelApiObject } from "./configs/axios-utils"
import { httpApiService } from "./services/http-api-service";

export interface ILatestNewsEntryRowViewModel {
  id: string;
  title: string;
  pictureUrl: string;
}

export const NewsAPI = {
  defaultPageSize: 9,

  getAll: async function (cancel = false) : Promise<ILatestNewsEntryRowViewModel[]> {
    const response = await httpApiService.get<ILatestNewsEntryRowViewModel[]>(`front-office/news-entries`, true);
    
    return response === undefined ? [] : response;
  }  
}

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(NewsAPI)