import { AxiosResponse } from "axios";
import { getApi } from "./configs/axios-configs"
import { defineCancelApiObject } from "./configs/axios-utils"
import { httpApiService } from "./services/http-api-service";

interface IDefaultCommandResponse {
  id: string;
}

interface ISubmitOrderDto {
  items: ISubmitOrderItemDto[];
}

export interface ICountryOrderRowViewModel
{
  id: string,   
  hrId: string,    
  createdAt: Date,
  status: number,
  totalAmount: number
}

export interface ICountryOrdersViewModel {
  count: number,
  items: ICountryOrderRowViewModel[]
}

export interface ISubmitOrderItemDto {
  productId: string;
  quantity: number;
}

export interface IOrderViewModel
{
  id: string;
	hrId: string;
	countryName: string;
	createdAt: Date;
	status: number;
	items: IOrderItemViewModel[];
	createdByName: string;
  shippingLocationName: string | null;
}

export interface IOrderItemViewModel
{
	productName: string;
	quantity: number;
	unitPriceWithDiscount: number;
	productSKU: string;
	discount: number;
}

export const OrderAPI = {
  defaultPageSize: 25,

  submitOrder: async function (items: ISubmitOrderItemDto[], cancel = false) : Promise<string> {

    const response = await getApi(true).post<ISubmitOrderDto, AxiosResponse<IDefaultCommandResponse>>(
      `front-office/orders`, 
      { items: items });
    
    return response?.data?.id;
  }, 

  getOrders: async function (page = 0, term = "", cancel = false) : Promise<ICountryOrdersViewModel> {
    const response = await httpApiService.get<ICountryOrdersViewModel>(`front-office/orders?`
      + `filter.searchTerm=${term}&`
      + `filter.page=${page}&`
      + `filter.pageSize=${this.defaultPageSize}`,
      true);   
    
    return response === undefined ? {count : 0, items: []} : response;
  },  

  getOrder: async function (id: string, cancel = false) : Promise<IOrderViewModel | undefined> {
    const response = await httpApiService.get<IOrderViewModel>(`front-office/orders/${id}`, true);   
    
    return response;
  }, 

  downloadOrder: async function (id: string, cancel = false) : Promise<any> {
    const response = await httpApiService.downloadFile(`front-office/orders/${id}/export`, true);   
  }  
}

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(OrderAPI)