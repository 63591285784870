import useLocalStorageState from "use-local-storage-state";
import { User } from "../types/user";

export function useCheckPermission(permission: string) {
    const [user, setUser] = useLocalStorageState<User>('user', {})

    if (!user || !user!.permissions.includes(permission)) {
        setUser(undefined);

        window.location.href = '/login';
    }
}