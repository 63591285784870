import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Slide, ToastContainer, toast } from 'react-toastify';
import { AccountApi } from '../../apis/account-api';
import logo from '../../assets/images/logo.png';

import 'react-toastify/dist/ReactToastify.css';
import "./login.scss";
import useLocalStorageState from 'use-local-storage-state';
import { User } from '../../types/user';
import usePageTitle from '../../hooks/usePageTitle';
import useGaTracker from '../../hooks/useGaTracker';

export default function Login() {
    useGaTracker();
    usePageTitle("Login");

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const [buttonClass, setButtonClass] = useState('');
    const [user, setUser] = useLocalStorageState<User>('user', {})
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [invalidCredentials, setInvalidCredentials] = useState(false);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!email || !password) {
            setEmailError(!email);
            setPasswordError(!password);
            return;
        }

        if (email.indexOf('@') === -1) {
            setEmailError(true);
            return;
        }

        setLoading(true);

        const result = await AccountApi.loginAsync(email, password);
        if (result.success && result.data && result.data.permissions.includes("country-manager")) {
            setUser(
                {
                    userId: result.data!.userId,
                    name: result.data!.name,
                    countryName: result.data!.countryName,
                    email: result.data!.email,
                    token: result.data!.token,
                    permissions: result.data!.permissions
                });

            setEmailError(false);
            setPasswordError(false);

            window.location.href = '/';
        }
        // else if (result.success && result.data && !result.data.permissions.includes("country-manager")) {
        //     toast.error('You do not have permission to use this site!', {
        //         position: toast.POSITION.BOTTOM_RIGHT
        //     });
        // }
        else if (result.success && result.data && !result.data.permissions.includes("country-manager")) {
            setInvalidCredentials(false);
            // toast.error('You do not have permission to use this site!', {
            //     position: toast.POSITION.BOTTOM_RIGHT
            // });
        }
        else {
            setInvalidCredentials(true);
        }

        setLoading(false);
    };

    return (
        <div className="page">
            <div className="container">
                <div className="login">
                    <img src={logo} alt="Logo" />
                    <h1>HELL Export Web Store</h1>
                    <div className="panel">
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <input type="text" className={`form-control ${emailError ? 'error' : ''}`} placeholder="Email" onChange={e => { setEmail(e.target.value); setEmailError(false); setInvalidCredentials(false); }} />
                                {emailError && <p className="error-message">Please enter a valid email address!</p>}
                            </div>
                            <div className="form-group">
                            <input type="password" className={`form-control ${passwordError ? 'error' : ''}`} placeholder="Password" onChange={e => { setPassword(e.target.value); setPasswordError(false); setInvalidCredentials(false); }} />
                                {passwordError && <p className="error-message">Please enter your password!</p>}
                            </div>
                            <div className="button-container">
                                <button disabled={loading || disableButton} className={buttonClass}>
                                    <div className={loading ? 'loader' : ''}>{loading ? '' : 'Login'}</div>
                                </button>
                                {loading && <div className="progress-bar" />}
                            </div>
                            {invalidCredentials && <p className="error-message login-failed">The email address and password did not match any account!</p>}
                        </form>
                    </div>
                </div>
                {/* <ToastContainer transition={Slide} /> */}
            </div>
        </div>
    )
}