import './header.scss';

import { NavLink, Link, useSearchParams } from "react-router-dom";
import { useEffect, useState } from 'react';
import { FiUser } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai"
import { IoMdSearch } from "react-icons/io";

import logo from '../../assets/images/logo.png';
import useLocalStorageState from 'use-local-storage-state';
import { User } from '../../types/user';
import { useOutsideClick } from '../../services/app-service';
import Cart, { ICartItem } from '../cart/cart';
import { HiOutlineShoppingCart } from 'react-icons/hi';

function Header() {
    const [scrolled, setScrolled] = useState(false);
    const [term, setTerm] = useState("");
    const [searchParams] = useSearchParams();
    const [profileOpened, setProfileOpened] = useState(false);
    const [user, setUser] = useLocalStorageState<User>('user', {})
    const [isActive, setIsActive] = useState(false)
    const handleClick = () => {
        setIsActive(current => !current)
    }

    const [cartItems, setCartItems] = useState<Array<ICartItem>>([]);

    useEffect(() => {
        const storedCartItems = JSON.parse(localStorage.getItem('cartItems') || '[]');
        setCartItems(storedCartItems);

        const handleStorageChange = () => {
            const updatedCartItems = JSON.parse(localStorage.getItem('cartItems') || '[]');
            setCartItems(updatedCartItems);
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        }
    }, [])

    const handleClearCart = () => {
        localStorage.removeItem('cartItems');
        setCartItems([]);
    };

    // const totalQuantity = cartItems.reduce((total, item) => total + item.quantityOrdered, 0);
    const handleDeleteItem = (id: string) => {
        const updatedCartItems = cartItems.filter(item => item.id !== id);
        localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
        setCartItems(updatedCartItems);
    }

    const calculateTotalPrice = () => {
        return cartItems.reduce((total, item) => total + item.priceWithDiscount * item.quantity, 0);
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        window.location.href = '/products?term=' + term;
    };

    const clearTerm = async () => {
        setTerm("");
        window.location.href = '/products';
    };

    function logout() {
        localStorage.removeItem('user');
        localStorage.removeItem('cartItems');

        window.location.href = '/login';
    }

    const profileDivRef = useOutsideClick(() => {
        setProfileOpened(false);
    });

    useEffect(() => {
        setTerm(searchParams.get("term") ?? "");
    }, []);

    const totalQuantity = cartItems.reduce((total, item) => total + item.quantity, 0);

    /**/

    const [editedItemId, setEditedItemId] = useState<string | null>(null);
    const [editedQuantity, setEditedQuantity] = useState<number>(0);

    const handleEditQuantity = (id: string, quantity: number) => {
        setEditedItemId(id);
        setEditedQuantity(quantity);
    };

    const handleSaveQuantity = (id: string) => {
        setEditedItemId(null);

        const updatedCartItems = cartItems.map(item =>
            item.id === id ? { ...item, quantity: editedQuantity } : item
        );

        // Frissítjük a kosár elemeit és a localStorage-t
        setCartItems(updatedCartItems);
        localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
    };

    useEffect(() => {
        if (isActive) {
            document.body.style.overflow = 'hidden';
        }
        // } else {
        //     document.body.style.overflow = 'auto';
        // }

        // return () => {
        //     document.body.style.overflow = 'auto';
        // };
    }, [isActive]);

    /**/

    return (
        <header className={scrolled ? "sticky" : ""}>
            <div className="search">
                <div className="container">
                    <div className="logo">
                        <Link to="/">
                            <img src={logo} alt="logo" className='logo' />
                            <span>Export Web Store</span>
                        </Link>
                    </div>
                    <div className='search-container'>
                        <form onSubmit={handleSubmit}>
                            <div className='search'><IoMdSearch /></div>
                            <input
                                name='search'
                                placeholder={term && term.length > 0 ? term : 'Search...'}
                                value={term}
                                onChange={e => setTerm(e.target.value)} />
                            <div className='close' onClick={() => clearTerm()}>
                                <AiOutlineClose />
                            </div>
                        </form>
                    </div>
                    <div className='secondary'>
                        {/* <Cart /> */}
                        <Link to='/checkout'><HiOutlineShoppingCart /></Link>
                        <div className={`profile ${profileOpened ? 'active' : ''}`} ref={profileDivRef} onClick={() => setProfileOpened(!profileOpened)}>
                            <FiUser />
                            {profileOpened &&
                                <div className='profile-dropdown'>
                                    {/* <div className='avatar'>
                                        <img src={logo} />
                                    </div> */}
                                    <div className='details'>
                                        <span className='name'>{user?.name}</span>
                                        <span className='country'>{user?.countryName}</span>
                                    </div>
                                    <ul>
                                        <li><Link className='change-password' to='/change-password'>Change password</Link></li>
                                        <li><Link className='my-orders' to="/orders">My Orders</Link></li>
                                        <li><Link className='logout' onClick={logout} to="/">Logout</Link></li>
                                    </ul>
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className={`opacity ${isActive ? 'visible' : 'hidden'}`}></div>
            <div className={`cart-card ${isActive ? 'card-block' : 'card-none'}`}>
                <div className='cart-head'>
                    <AiOutlineClose onClick={handleClick} />
                    <p>Cart</p>
                    <button onClick={handleClearCart}>Clear cart</button>
                </div>
                {cartItems.length === 0 ? (
                    <div>
                        <p className='empty'>Your cart is empty</p>
                    </div>
                ) : (
                    <div>
                        {cartItems.map((item, index) => (
                            <div key={index} className='item-product'>
                                {<img src={item.image ? item.image : placeholder} />}
                                <div>
                                    <p className='item-name'>{item.name}</p>
                                    <p className='item-price'>{formatNumber(item.price * item.quantity)} €</p>
                                    {editedItemId === item.id ? (
                                        <div>
                                            <input
                                                type="number"
                                                className='item-quantity'
                                                value={editedQuantity}
                                                min="1"
                                                onChange={(e) => setEditedQuantity(parseInt(e.target.value, 10))}
                                            />
                                            <button className='save' onClick={() => handleSaveQuantity(item.id)}>Save</button>
                                        </div>
                                    ) : (
                                        <div>
                                            <p className='item-quantity'>{item.quantity} pc</p>
                                            <button className='edit' onClick={() => handleEditQuantity(item.id, item.quantity)}>Edit</button>
                                            <button onClick={() => handleDeleteItem(item.id)}>Delete</button>
                                        </div>
                                    )}
                                    <button onClick={() => handleDeleteItem(item.id)}>Delete</button>
                                </div>
                            </div>
                        ))
                        }
                        <div className='total'>
                            <p>Total price: {formatNumber(calculateTotalPrice())} €</p>
                            <button>Submit</button>
                        </div>
                    </div>
                )}
            </div> */}
        </header>
    );
}

export default Header;