import { getApi } from "./configs/axios-configs"
import { defineCancelApiObject } from "./configs/axios-utils"

export interface ILoginResultApi {
  userId: string,
  name: string,
  countryName: string,
  email: string,
  token: string,
  permissions: string[]
}

export interface ILoginResult {
  success: boolean,
  data: ILoginResultApi | undefined
}

export interface IChangePasswordDto {
  userId: string,
  currentPassword: string,
  newPassword: string
}

export const AccountApi = {
  changePassword: async function (dto: IChangePasswordDto ) : Promise<boolean> {

    const response = await getApi(true).post<IChangePasswordDto, any>(`account/change-password`, dto);
    
    return response && response!.status == 200 ? true : false;
  }, 

  loginAsync: async function (email = "", password = "", cancel = false): Promise<ILoginResult> {    
    localStorage.removeItem('cartItems');

    const json = JSON.stringify(
      {
        email: email,
        password: password
      });

    const response = await getApi().post('account/login', json, {
      headers: {
        // Overwrite Axios's automatically set Content-Type
        'Content-Type': 'application/json'
      }
    }).catch(function (error) {

    });

    if (response && response!.status == 200) {
      return {
        success: true,
        data: {
          userId: response!.data.userId,
          email: response!.data.email,
          name: response!.data.name,
          countryName: response!.data.countryName,
          token: response!.data.token,
          permissions: response!.data.permissions
        }
      }
    }
    else {
      return { success: false, data: undefined };
    }
  }
}

const cancelApiObject = defineCancelApiObject(AccountApi)