import './layout.scss';

import { Outlet } from 'react-router-dom';
import Header from '../header/header';
import Footer from '../footer/footer'
import { Slide, ToastContainer } from 'react-toastify';
import { useState } from 'react';

function Layout() {    
    return (
        <div className='layout'>
            <>
                <Header />
                <Outlet />
                <Footer />                
                <ToastContainer transition={Slide} />
            </>
        </div>
    );
}

export default Layout;