import { defineCancelApiObject } from "./configs/axios-utils"
import { httpApiService } from "./services/http-api-service";

export interface IShipping {
  id: string;
  name: string;
}

export const ShippingAPI = {
  getAll: async function () : Promise<IShipping[]> {

    const response = await httpApiService.get<IShipping[]>(`front-office/shipping-locations`, true);
    
    return response === undefined ? [] : response;
  }  
}

const cancelApiObject = defineCancelApiObject(ShippingAPI)