import { useEffect, useRef } from 'react';
import { IPageItem } from '../types/page-item';

export const useOutsideClick = (callback: () => void) => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                callback();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [callback]);

    return ref;
};

export const delayAsync = (ms: number) => new Promise(
    resolve => setTimeout(resolve, ms)
);

export function getPageItems(count: number, defaultPageSize: number, page: number): IPageItem[] {
    let pageItems: IPageItem[] = [];
    let lastPrintedPage = 0;
    let dotPrinted = false;

    for (let i = 0; count > i * defaultPageSize; i++) {
        if (i == 0 || i == 1 ||
            i == page - 1 || i == page || i == page + 1 ||
            i == Math.floor(count / defaultPageSize) - 1 ||
            i == Math.floor(count / defaultPageSize)) {
            pageItems.push({ key: i, page: i, isActive: i == page, isDot: false });
            dotPrinted = false;
        }
        else if (!dotPrinted && (lastPrintedPage + 1) != i) {
            pageItems.push({ key: i, page: -1, isActive: false, isDot: true });
            dotPrinted = true;
        }
    }

    return pageItems;
}