import './orders.scss';

import { useEffect, useState } from 'react';
import { ICountryOrdersViewModel, OrderAPI } from '../../apis/order-api';
import { useSearchParams } from 'react-router-dom';
import { useCheckPermission } from '../../services/auth-service';
import { IPageItem } from '../../types/page-item';
import { getPageItems } from '../../services/app-service';
import moment from 'moment';
import { formatNumber, translateStatus } from '../../services/app-messages-service';
import useGaTracker from '../../hooks/useGaTracker';
import usePageTitle from '../../hooks/usePageTitle';

function Home() {
    useCheckPermission("country-manager");
    useGaTracker();
    usePageTitle("Orders");

    const [searchParams] = useSearchParams();

    const [loading, setLoading] = useState<boolean>();
    const [orders, setOrders] = useState<ICountryOrdersViewModel>();
    const [term, setTerm] = useState<string>();
    const [page, setPage] = useState<number>();
    const [pageItems, setPageItems] = useState<IPageItem[]>();


    const getAll = async () => {
        const term = searchParams.get("term") ?? "";
        const page = +(searchParams.get("page") ?? 0);

        setTerm(term);
        setPage(page);

        setLoading(true);
        // await delayAsync(60000);

        let data = await OrderAPI.getOrders(page, term);
        if (data) {
            let items = data.items;
            let count = data.count;

            let pageItems = getPageItems(data.count, OrderAPI.defaultPageSize, page);

            setPageItems(pageItems);

            setOrders(data);
        }
        setLoading(false);
    };
    
    useEffect(() => {
        getAll();
    }, []);

    return (
        <div className='orders'>
            <div className='container'>
                <div className='title-container'>
                    <h1>Orders</h1>
                </div>
                {!loading && <>
                    {orders && orders.items.length > 0 &&
                        <div className='orders-container'>
                            <table>
                                <tr>
                                    <th>ID</th>
                                    <th>Date</th>
                                    <th>Status</th>
                                    <th>Total amount</th>
                                </tr>
                                {orders.items.map((order) => (
                                    <tr key={order.hrId}>
                                        <td><a href={`/orders/${order.id}`}>{order.hrId}</a></td>
                                        <td>{moment.utc(order.createdAt).local().format("YYYY.MM.DD. HH:mm")}</td>
                                        <td><span className={`status status-${order.status}`}>{translateStatus(order.status)}</span></td>
                                        <td>{formatNumber(order.totalAmount)} €</td>
                                    </tr>
                                ))}
                            </table>
                        </div>
                    }
                    {(!orders || orders.items.length == 0) && <div className='empty'><p>No orders found!</p></div>}
                    {pageItems &&
                        <div className="pagination">
                            {pageItems.map((pageItem) => (
                                <>
                                    {pageItem.isDot === false && <a className={`${pageItem.isActive ? "active" : ""}`} href={`/orders?page=${pageItem.page}`}>{(pageItem.page + 1)}</a>}
                                    {pageItem.isDot === true && <span>...</span>}
                                </>
                            ))}
                        </div>
                    }</>
                }
                {loading && <div className='loading'><p>Loading...</p></div>}
            </div>
        </div>
    );
}

export default Home;
