import { useEffect, useState } from 'react';
import './checkout.scss';
import { ICartItem } from '../../components/cart/cart';
import { formatNumber } from '../../services/app-messages-service';
import placeholder from '../../assets/images/placeholder.jpg';
import { ISubmitOrderItemDto, OrderAPI } from '../../apis/order-api';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { ShippingAPI } from '../../apis/shipping-api';


function Checkout() {
    const [cartIsActive, setCartIsActive] = useState(false);
    const [cartItems, setCartItems] = useState<Array<ICartItem>>([]);
    const [editedItemId, setEditedItemId] = useState<string | null>(null);
    const [editedQuantity, setEditedQuantity] = useState<number>(0);
    const [loading, setLoading] = useState(false);
    const [filterShippingOptions, setfilterShippingOptions] = useState<{ value: string, label: string }[]>([]);

    // const filterInStockChanged = (selectedOption: any) => { setfilterShippingOptions(selectedOption); };

    const handleClick = () => {
        setCartIsActive(current => !current)
    }

    useEffect(() => {
        const storedCartItems = JSON.parse(localStorage.getItem('cartItems') || '[]');
        setCartItems(storedCartItems);

        const handleStorageChange = () => {
            const updatedCartItems = JSON.parse(localStorage.getItem('cartItems') || '[]');
            setCartItems(updatedCartItems);
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        }
    }, [])

    const handleClearCart = () => {
        localStorage.removeItem('cartItems');
        setCartItems([]);
    };

    // const totalQuantity = cartItems.reduce((total, item) => total + item.quantityOrdered, 0);
    const handleDeleteItem = (id: string) => {
        const updatedCartItems = cartItems.filter(item => item.id !== id);
        localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
        setCartItems(updatedCartItems);
    }

    const calculateTotalPrice = () => {
        return cartItems.reduce((total, item) => total + item.priceWithDiscount * item.quantity, 0);
    }

    const totalQuantity = cartItems.reduce((total, item) => total + item.quantity, 0);


    const handleEditQuantity = (id: string, quantity: number) => {
        setEditedItemId(id);
        setEditedQuantity(quantity);
    };

    const handleSaveQuantity = (id: string) => {
        setEditedItemId(null);

        const updatedCartItems = cartItems.map(item =>
            item.id === id ? { ...item, quantity: editedQuantity } : item
        );

        setCartItems(updatedCartItems);
        localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
    };

    const handleCloseClick = () => {
        setCartIsActive(false);
    };

    useEffect(() => {
        if (cartIsActive) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    });

    const [isActive, setIsActive] = useState(false);

    const handleCheckoutClick = () => {
        setIsActive(true);
    };

    const handleNoButtonClick = () => {
        setIsActive(false);
    };

    useEffect(() => {
        getAll();

        if (isActive) {
            document.body.style.overflowY = 'hidden';
        } else {
            document.body.style.overflowY = 'auto';
        }
    }, [isActive]);

    const submitOrder = async () => {
        setLoading(true);
        setIsActive(false);

        let items: ISubmitOrderItemDto[] = cartItems.map<ISubmitOrderItemDto>(item => {
            return { productId: item.id, quantity: item.quantity };
        });

        try {
            let orderId = await OrderAPI.submitOrder(items);

            if (orderId) {
                toast.success('Thank you for your order. Checkout completed!', {
                    position: toast.POSITION.BOTTOM_RIGHT
                });

                localStorage.removeItem('cartItems');
                setCartItems([]);
            }
        } catch (error) {
            toast.error('Apologies, an error occurred during checkout. Please contact our support team for assistance with your order.', {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        } finally {
            setLoading(false);
            setCartIsActive(false);
        }
    };

    const [filterShippingOption, setFilterShippingOption] = useState<{ value: string, label: string }>({ value: 'all', label: 'All' });
    const filterInStockChanged = (selectedOption: any) => { setFilterShippingOption(selectedOption); };

    const getAll = async () => {
        setLoading(true);
        // updateSearchParams();

        let shippings = await ShippingAPI.getAll();
        if (shippings) {
            setfilterShippingOptions(shippings.map(x => ({ value: x.id, label: x.name })));
        }


        setLoading(false);
    };

    return (
        <>
            <div className='checkout'>
                <div className="container">
                    <div>
                        <div className="row">
                            <div className="col-75">
                                {cartItems.length === 0 ? (
                                    <div>
                                        <p className='empty'>Your cart is empty</p>
                                    </div>
                                ) : (
                                    <div>
                                        {cartItems.map((item, index) => (
                                            <div key={item.sku} className="checkout__card">
                                                <div key={index} className="row">
                                                    <div className="col-10 image__center">
                                                        <div className='image'>
                                                            <img src={item.image ? item.image : placeholder} />
                                                        </div>
                                                    </div>
                                                    <div className="col-90">
                                                        <div className="flex">
                                                            <div>
                                                                <div className='title'>{item.name}</div>
                                                            </div>
                                                            <div className='product__details'>
                                                                <div className="price">{formatNumber(item.priceWithDiscount * item.quantity)} €</div>
                                                                <div>
                                                                    {editedItemId === item.id ? (
                                                                        <div className='flex'>
                                                                            <input
                                                                                type="number"
                                                                                className='item-quantity'
                                                                                value={editedQuantity}
                                                                                min="1"
                                                                                onChange={(e) => setEditedQuantity(parseInt(e.target.value, 10))}
                                                                            />
                                                                            <button className='save' onClick={() => handleSaveQuantity(item.id)}>Save</button>
                                                                        </div>
                                                                    ) : (
                                                                        <div className='gap'>
                                                                            <p className='item-quantity'>{item.quantity} pc</p>
                                                                            <div className='buttons flex'>
                                                                                <button className='edit' onClick={() => handleEditQuantity(item.id, item.quantity)}>Edit</button>
                                                                                <button onClick={() => handleDeleteItem(item.id)}>Delete</button>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <div className="col-25">
                                <div>
                                    {cartItems.length === 0 ? (
                                        <div></div>
                                    ) : (
                                        <div className="total">
                                            <div className='ship'>
                                                <span>Ship to: </span>
                                                <Select
                                                    className='select-filter-stock select'
                                                    classNamePrefix="app-select"
                                                    value={filterShippingOption}
                                                    // menuIsOpen={true}
                                                    onChange={filterInStockChanged}
                                                    menuPlacement="top"
                                                    options={filterShippingOptions}
                                                />
                                            </div>
                                            <div className='flex'>
                                                <p>Total: {formatNumber(calculateTotalPrice())} €</p>
                                                <button onClick={handleCheckoutClick}>Checkout</button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className={isActive ? 'security__opacity active' : 'security__opacity'}></div>
                <div className={isActive ? 'popup active' : 'popup'}>
                    <div className="panel">
                        <h1>Are you sure you want to process this order?</h1>
                        <div className="buttons">
                            <button onClick={handleNoButtonClick}>no</button>
                            <button onClick={() => submitOrder()}>yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Checkout;
