export function appMessageLocalization(t: string): string {
    if (t == "PRODUCT_NOT_FOUND") return "The product not found or deleted!";
    else if (t == "FAILED_LOGIN") return "The email address and password did not match any account!";
    else return t;
}

export const translateStatus = (status: number) => {
    if (status == 1) return "New";
    else if (status == 2) return "Waiting to approve";
    else if (status == 3) return "Approved";
    else if (status == 12) return "Shipped";
    else if (status == 11) return "Rejected";
    else if (status == 10) return "Deleted";
    else return "Unknown";
}

export function formatNumber(n: number, digits: number = 2): string {
    return n.toLocaleString( undefined, { minimumFractionDigits: digits });
}