import "react-responsive-carousel/lib/styles/carousel.min.css";
import './news.component.scss'

import { FC, ReactElement, useEffect, useState } from 'react';

import { ILatestNewsEntryRowViewModel, NewsAPI } from '../../apis/news-api';
import NewsItem from '../news-item/news-item.component';
import { Carousel } from 'react-responsive-carousel';

import tempImg1 from '../../assets/temp/402544085_732375408928571_8057225138159519038_n.png';
import tempImg2 from '../../assets/temp/307321259_5553872851333803_5736868392099164097_n.jpg';
import tempImg3 from '../../assets/temp/387850369_6785439798177096_14464099260476486_n.jpg';
import { IoIosArrowBack, IoIosArrowDropleft, IoIosArrowDropright, IoIosArrowForward } from "react-icons/io";

const News: FC = ({ }): ReactElement => {
  const [news, setNews] = useState<ILatestNewsEntryRowViewModel[]>();

  const getAll = async () => {
    let data = await NewsAPI.getAll();
    if (data) {
      setNews(data);
    }
  };

  useEffect(() => {
    getAll();
  }, []);

  return (
    <>{news &&
      <div className='news'>
        <div className='container'>
          <Carousel
            renderArrowPrev={(onClickHandler, hasPrev) => hasPrev && (
              <button className="carousel-custom-arrow carousel-prev-arrow" onClick={onClickHandler}>
                <IoIosArrowBack />
              </button>
            )
            }
            renderArrowNext={(onClickHandler, hasNext) =>
              hasNext && (
                <button className="carousel-custom-arrow carousel-next-arrow" onClick={onClickHandler}>
                  <IoIosArrowForward />
                </button>
              )
            }
            showArrows={false}
            infiniteLoop={true}
            showThumbs={false}
            autoPlay={true}
            showStatus={false}>
            {news.map((newsItem) => (
              <NewsItem key={newsItem.id} id={newsItem.id} title={newsItem.title} pictureUrl={newsItem.pictureUrl} />
            ))}
          </ Carousel>
        </div>
      </div>}
    </>);
};

export default News;