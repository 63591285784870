import './news-item.component.scss'

import { FC, ReactElement, useState } from 'react';

import useLocalStorageState from 'use-local-storage-state';
import placeholder from '../../assets/images/placeholder.jpg';

export interface INewsItem {
  id: string;
  title: string;
  pictureUrl: string;
}
export interface INewsViewed {
  ids: string[];
}

const NewsItem: FC<INewsItem> = ({ id, title, pictureUrl }): ReactElement => {
  const [isOpen, setIsOpen] = useState(true);
  const [newsViewed, setNewsViewed] = useLocalStorageState<INewsViewed>('news-viewed', {});

  function toggle(id: string) {
    if (newsViewed === undefined){
      setNewsViewed({ ids: [id] });
    }
    else{
      newsViewed!.ids.push(id);
      setNewsViewed(newsViewed);
    }

    setIsOpen((isOpen) => !isOpen);
  }

  if (isOpen) {
    return (
      <>      
        <div key={id} className='news-item'>
            <img className={`${pictureUrl ? '' : 'placeholder'}`} src={pictureUrl ? pictureUrl : placeholder} alt={title} />   
        </div>
      </>
    );
  }
  else {
    return (<></>);
  }
};

export default NewsItem;