import './order.scss';

import { useEffect, useState } from 'react';
import { IOrderViewModel, OrderAPI } from '../../apis/order-api';
import { useParams } from 'react-router-dom';
import { useCheckPermission } from '../../services/auth-service';
import moment from 'moment';
import { MdOutlineNavigateNext } from 'react-icons/md';
import { formatNumber, translateStatus } from '../../services/app-messages-service';
import useGaTracker from '../../hooks/useGaTracker';
import usePageTitle from '../../hooks/usePageTitle';

function Home() {
    useCheckPermission("country-manager");
    useGaTracker();
    usePageTitle("Order");

    const { id } = useParams();
    const [loading, setLoading] = useState<boolean>();
    const [order, setOrder] = useState<IOrderViewModel>();
    const [orderSumQuantity, setOrderSumQuantity] = useState<number>();
    const [orderSumPrice, setOrderSumPrice] = useState<number>();

    const getAll = async () => {
        if (id) {
            setLoading(true);
            // await delayAsync(60000);

            let data = await OrderAPI.getOrder(id);

            if (data?.items) {
                let q = 0;
                let p = 0;

                for (let i = 0; i < data!.items.length; i++) {
                    q += data!.items[i].quantity;
                    p += data!.items[i].quantity * data!.items[i].unitPriceWithDiscount;
                }

                setOrderSumQuantity(q);
                setOrderSumPrice(p);
            }

            setOrder(data);
            setLoading(false);
        }
    };

    const handleDownloadClick = async (id: string | undefined) => {
        if (id !== undefined){
            await OrderAPI.downloadOrder(id);
        }
    };

    useEffect(() => {
        getAll();
    }, []);

    return (
        <div className='order'>
            <div className='container'>
                <div className='breadcrumb'>
                    <a href='/orders'>Orders</a>
                    <span className='separator'><MdOutlineNavigateNext /></span>
                    <span className='current'>{order?.hrId}</span>
                </div>
                <div className='title-container'>
                    <h1>Order: {order?.hrId}</h1>
                    <button onClick={() => handleDownloadClick(order?.id)}>Download</button>
                </div>
                {!loading && order && order.items && 
                    <div className='order-container'>
                        <div className='details'>
                            <div><label>Date (UTC):</label><span>{moment.utc(order.createdAt).local().format("YYYY.MM.DD. HH:mm")}</span></div>
                            <div><label>Country:</label><span>{order.countryName}</span></div>
                            <div><label>Name:</label><span>{order.createdByName}</span></div>
                            <div><label>Status:</label><span>{translateStatus(order.status)}</span></div>
                            {order.shippingLocationName && <div><label>Shipping to:</label><span>{order.shippingLocationName}</span></div>}
                        </div>
                        <h2>Products</h2>
                        <table className="products">
                            <thead>
                                <tr>
                                    <th>SKU</th>
                                    <th>Name</th>
                                    <th>Quantity</th>
                                    <th>Price</th>
                                    <th>Total price</th>
                                    <th>Discount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {order.items.map((item) => (
                                    <tr key={item.productSKU}>
                                        <td>{item.productSKU}</td>
                                        <td>{item.productName}</td>
                                        <td>{formatNumber(item.quantity)}</td>
                                        <td>{formatNumber(item.unitPriceWithDiscount)} €</td>
                                        <td>{formatNumber(item.quantity * item.unitPriceWithDiscount)} €</td>
                                        <td>{item.discount ? formatNumber(item.discount)+' %' : '-'}</td>
                                    </tr>
                                ))}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>Total</td>
                                    <td></td>
                                    <td>{formatNumber(orderSumQuantity!)}</td>
                                    <td></td>
                                    <td>{formatNumber(orderSumPrice!)} €</td>
                                    <td></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                }
                {loading && <div className='loading'><p>Loading...</p></div>}
            </div>
        </div>
    );
}

export default Home;
