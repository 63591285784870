import { defineCancelApiObject } from "./configs/axios-utils"
import { httpApiService } from "./services/http-api-service";

export interface ICategory {
  id: string;
  name: string;
}

export const CategoryAPI = {
  getAll: async function () : Promise<ICategory[]> {

    const response = await httpApiService.get<ICategory[]>(`front-office/categories`, true);
    
    return response === undefined ? [] : response;
  }  
}

const cancelApiObject = defineCancelApiObject(CategoryAPI)