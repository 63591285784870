import { Link } from 'react-router-dom';
import './not-found.scss';
import useGaTracker from '../../hooks/useGaTracker';
import usePageTitle from '../../hooks/usePageTitle';

function NotFound() {
    useGaTracker();
    usePageTitle("404");
    
    return (
        <div className='page-404'>
            <div className="not-found">
                <h1>404</h1>
                <p>Page not found.</p>
                <Link className='button' to='/'>Home</Link>
            </div>
        </div>
    );
}

export default NotFound;